.scroll-box {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.scroll-box__wrapper {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  box-sizing: border-box;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.scroll-box__wrapper::-webkit-scrollbar {
  display: none;
}

.scroll-box__container {
  height: 100%;
  display: inline-flex;
}
